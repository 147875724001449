function selectTab(selectedTabLink){
  $('.tab').removeClass('tab--active');
  $(selectedTabLink).parent().addClass('tab--active');
  var tabContent = $(selectedTabLink).data('tab-id');
  $('.tab-content').hide();
  $('#' + tabContent + '-content').show();
  window.location.hash = '#' + tabContent;
}

$(document).ready(function(){
  if ( $('.tab-link').length > 0 ){
    $('.tab-link').on('click', function(e){
      e.preventDefault();
      selectTab(this);
    });

    var initialTab;

    if (window.location.hash) {
      id = window.location.hash.substr(1);
      initialTab = $('*[data-tab-id="' + id + '"]');
    } else {
      initialTab = $('.tab-link')[0];
    }

    selectTab(initialTab);
  }
});
