import Rails from '@rails/ujs';

export const debounce = (wait, func) => {
  let immediate = null;
  let timeout;

  return () => {
    let context = this, args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const performSearch = (searchForm) => {
  Rails.fire(searchForm, 'submit');
};
