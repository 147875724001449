import moment from 'moment';
import * as search from './utils/sidebarSearch';

window.jQuery = $;
window.$ = $;
window.moment = moment;

$(document).ready(() => {
  $('.sidebar_search').each((index, form) => {
    $(form)
      .find("input[type='search']")
      .keyup(
        search.debounce(250, () => {
          search.performSearch($(form)[0]);
        }),
      );
  });

  $('.js-search-on-keyup').each(function () {
    const form = $(this).parents('form')[0];
    const debouncedSearch = search.debounce(250, () => {
      search.performSearch(form);
    });
    $(this).keyup(debouncedSearch);
  });

  $('.js-search-on-change').change(function () {
    const form = $(this).parents('form')[0];
    search.performSearch(form);
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const button = document.querySelector('.add-new-commitment');
  const form = document.querySelector('.new-commitment');

  if (button && form) {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      button.classList.toggle('--hidden');
      form.classList.toggle('--hidden');
    });
  }
});
