import tomSelectInput from "./tomSelectInput";

function cb(start, end) {
  if ( start && end ){
    $('#reportrange span').html(`${moment(start).format('MMMM D, YYYY')  } - ${  moment(end).format('MMMM D, YYYY')}`);
    $('#q_starts_at_gteq').val(moment(start).startOf('day').format('YYYY-MM-DD'));
    $('#q_starts_at_lteq').val(moment(end).endOf('day').format('YYYY-MM-DD'));
  } else {
    $('#reportrange span').html('');
    $('#q_starts_at_gteq').val('');
    $('#q_starts_at_lteq').val('');
  }
}

document.addEventListener("DOMContentLoaded",()=> {
  $('.js-date-filter').each(function(){
    if ( $(this).data('start') && $(this).data('end') ){
      const start = $(this).data('start');
      const end = $(this).data('end');
      cb(start, end);
    }
  });
});
