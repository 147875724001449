import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: "<%= ENV['SENTRY_DSN'] %>",
  environment: "<%= ENV['NODE_ENV'] %>",
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
